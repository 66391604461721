<template>
  <div class="position-relative" v-click-outside="hideDropdown">
    <v-text-field
      v-model="value"
      :label="label"
      outlined
      dense
      hide-details
      @focus="showDropdown"
      @keyup="getSuggestion"
    ></v-text-field>
    <div class="position-absolute result-list" v-if="dropdown">
      <template v-if="suggestions.length > 0">
        <div
          class="result-item"
          :class="{
            'selected-item': selected.id && selected.id === suggestion.id,
          }"
          v-for="suggestion in suggestions"
          :key="`s_${suggestion.id}`"
          @click="selectItem(suggestion)"
        >
          <div>{{ suggestion.name }}</div>
          <div>{{ suggestion.phone }}</div>
          <div class="text-capitalize">{{ addressTxt(suggestion) }}</div>
        </div>
      </template>
      <template v-else>
        <div class="result-item font-italic">
          <div>
            {{
              !value
                ? $t("labels.enter_text_for_search")
                : $t("labels.no_result_and_auto_create")
            }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "SelectSearch",
  props: {
    name: {
      type: String,
      value: () => null,
    },
    nameType: {
      type: String,
      value: () => null,
    },
    label: {
      type: String,
      value: () => null,
    },
    suggestionApiUrl: {
      type: String,
      value: () => null,
    },
    defaultValue: {
      type: String,
      value: () => null,
    },
  },
  data: () => ({
    value: null,
    dropdown: false,
    selected: {},
    newItem: {},
    suggestions: [],
  }),
  watch: {
    defaultValue() {
      this.value = this.defaultValue;
    },
    value() {
      this.onChange();
    },
  },
  mounted() {},
  methods: {
    onChange() {
      this.$emit("onChange", {
        name: this.name,
        value: this.value,
      });
    },
    getSuggestion: debounce(function () {
      httpClient
        .post("/get-receivers", { [this.nameType]: this.value })
        .then(({ data }) => {
          this.suggestions = [...data];
        });
    }, 500),
    setNewItem() {
      const newItem = {
        id: this.generateRandomString(5),
        name: null,
        phone: null,
        address: null,
      };
      newItem[this.nameType] = this.value;
      this.newItem = { ...newItem };
      this.selectItem(newItem);
    },
    selectItem(item) {
      this.selected = { ...item };
      this.value = item[this.nameType];
      this.$emit("onSelectSuggest", this.selected);
      this.hideDropdown();
    },
    addressTxt(item) {
      const addr = [];
      if (!item.id) {
        return null;
      }
      if (item.address) {
        addr.push(item.address);
      }
      if (item.ward_name) {
        addr.push(item.ward_name);
      }
      if (item.county_name) {
        addr.push(item.county_name);
      }
      if (item.city_name) {
        addr.push(item.city_name);
      }
      return addr.join(", ");
    },
    showDropdown() {
      this.dropdown = true;
    },
    hideDropdown() {
      this.dropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.result-list {
  width: 100%;
  background: #fff;
  z-index: 1;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  max-height: 280px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;

  .result-item {
    padding: 8px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 12px;

    &:hover {
      background: #efefef;
      cursor: pointer;
    }

    &.selected-item {
      background-color: #1976d2;
      color: #fff;
      font-weight: 600;
    }
  }
}
</style>
